<template>
    <c-modal class="max-w-[600px]"
             v-bind:open="open"
             v-bind:title="__('Edit notes')"
             v-on:closeModal="closeDestination"
    >
        <template v-slot:body>
            <div class="flex flex-col" v-if="sn">
                <c-control-text name="notes" rows="10" type="textarea"
                                v-model="sn.notes"
                />
            </div>
        </template>
        <template v-slot:footer>
            <div class="bg-white flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" dusk="cancel-button" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" dusk="save-button" v-on:click="updateNote">
                    {{ __('Save') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, ref, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import {
        CButton,
        CControlText,
        CModal,
    } from '@teamfurther/cinderblock';

    import WorksheetRepository from '../../../../repositories/WorksheetRepository';
    import { useRequestStore } from '../../../../stores/request';

    const __ = inject('__');
    const open = ref<boolean>(true);
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();
    const sn = ref<object>();
    const snId = route.params.sn;
    const worksheet = ref<object>();
    const worksheetId = route.params.worksheet;
    const worksheetRepository = WorksheetRepository.getInstance();

    function closeDestination() {
        open.value = false;

        if (!window.history.state.back) {
            router.push({ name : 'tickets' });
        } else {
            router.go(-1);
        }
    }

    async function getWorksheet() {
        worksheet.value = await worksheetRepository.show(worksheetId, {
            include: [
                'serialNumbers',
            ]
        });
    }

    async function updateNote() {
        await requestStore.put('worksheets/sn/' + snId, {
            notes: sn.value.notes
        }).then(async () => {
            open.value = false;

            requestStore.redirect({ name: 'worksheets.edit' }, {
                message: __('Serial number successfully updated.'),
                status: 200,
                title: __('Success'),
            });
        });
    }

    onMounted(async () => {
        await getWorksheet();

        for (let s of worksheet.value.serial_numbers) {
            if (s.id === snId) {
                sn.value =  s;
            }
        }
    });
</script>
