import { inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios, { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

import { useSessionStore } from './session';

export const useRequestStore = defineStore('request', () => {
    const __ = inject('__');
    const apiBaseUrl: string = import.meta.env.VITE_API_BASE_URL;
    const can = inject('can');
    const errors = ref<object>();
    const loading = ref<number>(0);
    const message = ref<string>();
    const sessionStore = useSessionStore();
    const status = ref<number>();
    const route = useRoute();
    const router = useRouter();
    const title = ref<string>();

    function destroy(url: string, config : object = {}): Promise<AxiosResponse> {
        return axios.delete(`${apiBaseUrl}${url}`, config);
    }

    function get(url: string, config : object = {}): Promise<AxiosResponse> {
        return axios.get(`${apiBaseUrl}${url}`, config);
    }

    function post(url: string, data: object, config : object = {}): Promise<AxiosResponse> {
        return axios.post(`${apiBaseUrl}${url}`, data, config);
    }

    function put(url: string, data: object, config : object = {}): Promise<AxiosResponse> {
        return axios.put(`${apiBaseUrl}${url}`, data, config);
    }

    function redirect(redirect: object, alert: object = {}) {
        errors.value = alert && alert.errors ? alert.errors : null;
        message.value = alert && alert.message ? alert.message : '';
        status.value = alert && alert.status ? alert.status : null;
        title.value = alert && alert.title ? alert.title : '';

        if (route.name === redirect.name) {
            window.location.reload();
        } else {
            router.push(redirect);
        }
    }

    onMounted(() => {
        axios.interceptors.request.use(async config => {
            loading.value++;

            if (window.localStorage.getItem('token')) {
                config.headers = {
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
                    'Accept': `application/json`,
                }
            }

            const alert = JSON.parse(window.localStorage.getItem('alert'));
            window.localStorage.removeItem('alert');

            errors.value = alert && alert.errors ? alert.errors : null;
            message.value = alert && alert.message ? alert.message : '';
            status.value = alert && alert.status ? alert.status : null;
            title.value = alert && alert.title ? alert.title : '';

            return config;
        }, (error) => {
            decrementLoading();

            return Promise.reject(error);
        });

        axios.interceptors.response.use(
            (response) => {
                decrementLoading();

                return response;
            },
            (error) => {
                decrementLoading();

                status.value = error.response.status;
                title.value = __('Error');

                if (error.response.status === 401) {
                    message.value = __('Your session has expired. Please log in again.');

                    sessionStore.logUserOut();
                } else if (error.response.status === 402) {
                    redirect({ name : can('subscriptions.manage') ? 'subscriptions.billing' : 'errors.402' });

                    return Promise.reject();
                } else if (error.response.status === 404) {
                    redirect({ name : 'errors.404' });

                    return Promise.reject();
                } else if (error.response.status === 422) {
                    errors.value = error.response.data.errors;

                    message.value = error.response.data.message;
                    title.value = __('Your form has errors. Please correct them before proceeding.');
                } else {
                    message.value = error.response.data.message || error.response.data.error;
                }

                return Promise.reject(error);
            }
        );
    });

    function decrementLoading() {
        const isEnvironmentTest = navigator.webdriver;
        const timeout = isEnvironmentTest ? 1000 : 0;

        setTimeout(() => {
            loading.value--;
        }, timeout);
    }

    return {
        destroy,
        errors,
        get,
        loading,
        message,
        post,
        put,
        redirect,
        status,
        title
    }
});
